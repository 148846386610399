import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

var lang;

if (JSON.parse(localStorage.getItem('local'))) {
	lang = JSON.parse(localStorage.getItem('local'));
} else {
	lang = (window.navigator.language) ? window.navigator.language : 'cs';
	if (lang !== 'cs' && lang !== 'de') {
		lang = 'cs'
	}
	localStorage.setItem('local', JSON.stringify(lang));
}

const messages = {
	cs: {
		title: 'Registrace do Fondu malých projektů<br> v rámci programu Interreg<br>Rakousko – Česko 2021-2027',
		universalTxt: {
			closeBtn: 'Zavřít',
			saveBtn: 'Uložit',
			delete: 'Smazat',
			logout: 'Odhlásit',
			edit: 'Editace',
			edit_final: '',
			exportDatabank: 'Export databanky',
			visible_on: 'Zviditelnit',
			visible_off: 'Skrýt',
		},
		homepage: {
			registration_q: 'Nejste registrovaní?',
			register: 'Registrujte se',
			cz: 'Česky',
			de: 'Německy'
		},
		passwordReset: {
			title: 'Obnova hesla',
			message: 'Vyplňte svůj registrační e-mail a my vám na něj pošleme nové heslo.',
			reset: 'Obnovit heslo',
			change: 'Změnit heslo',
			passwdTest: 'Ověření hesla',
			passwd: 'Heslo',
			login: 'Přihlaste se'
		},
		login: {
			title: 'Přihlaste se',
			login: 'Přihlásit',
			passwdForget: 'Zapomněli jste heslo?'
		},
		registration: {
			reg: 'Registrujte se',
			email: 'E-mail',
			pswd: 'Heslo',
			pswdTest: 'Ověření hesla',
			regBtn: 'Registrovat',
			login: 'Jste již registrovaní a chcete se přihlásit?',
			backToHp: 'Zpět na úvodní stránku',
		},
		registrationConfirm: {
			login: 'Přihlaste se'
		},
		projects: {
			title: 'Projekty',
			newProject: 'Založit nový projekt',
			downloadBtn: 'Exportovat do PDF',
			attachments: 'Přílohy',
			infoAboutUploadFile: 'Je povoleno nahrát soubory následujícího typu: image/*, .pdf, .xlsx, .docx, .xls, .ppt, .doc, .odt, .ods, .rar, .zip.',
			maxFileSize: 'Maximální velikost nahrávaných souborů je <b>128 MB</b>.',
			fileCompress: 'Objemné soubory dostatečně zkomprimujte do *.zip, nebo *.rar souboru.',
			phase_1: '1.) Vyplňte formulář',
			phase_2: '2.) Stáhněte vyplněný formulář',
			phase_3: '3.) Nahrajte přílohy',
			phase_4: '4.) Podejte finální verzi projektové žádosti',
			checkProjectBtn: 'Odeslat k zaregistrování žádosti',
			checkProjectBtn1: 'Odeslat opravenou žádost k opětovnému zaregistrování',
			messageSend: 'Formulář byl odeslán k zaregistrování',
			deleteItem: 'Opravdu smazat?',
			tableColVisibility: 'Viditelnost pro: ',
			tableColDate: 'Podání žádosti',
			tableColCall: 'Výzva č.',
			tableColRegN: 'Reg. č.',
			tableColName: 'Název',
			tableColUserName: 'Žadatel',
			tableColState: 'Stav',
			documents: 'Projektová dokumentace',
			confirmText: 'Žádost o dotaci bude nyní podána a odeslána k zaregistrování. Po odeslání k zaregistrování už nebude možná editace žádosti o dotaci. Přejete si pokračovat?',
			type_1: 'KCR',
			type: 'Typ projektu',
			form: {
				ttl_1: '1. OBECNÉ ÚDAJE O PROJEKTU',
				ttl_2: '2. ÚDAJE O ŽADATELI',
				ttl_3: 'ÚDAJE O PROJEKTOVÉM PARTNEROVI č.',
				ttl_4: 'ÚDAJE O PROJEKTU',
				ttl_5: 'KVALITA PŘESHRANIČNÍ SPOLUPRÁCE',
				ttl_6: 'ROZPOČET A PLÁN FINANCOVÁNÍ',
				showPartner2: 'Přidat projektového partnera č. 2',
				removePartner2: 'Odebrat projektového partnera č. 2' ,
				ph_2_4_number: 'Číslo účtu',
				ph_2_4_code: 'Kód banky',
				ph_2_4_name: 'Název banky',
				ph_2_4_iban: 'IBAN',
				ph_2_4_bic_swift: 'BIC/SWIFT',
				ph_2_5_contact: 'Jméno, příjmení, funkce',
				ph_2_8_contact: 'Jméno, příjmení',
				ph_2_5_tel: 'Telefon (+420 nebo +43)',
				ph_2_5_email: 'E-mail',
				check_2_6_a: 'Žadatel má podle platného právního stavu nárok na odpočet daně z přidané hodnoty za činnosti, které budou v rámci projektu prováděny.',
				check_2_6_c: 'Žadatel má zkušenosti s realizací přeshraničních projektů.',
				check_2_6_d: 'Projekt je v souladu s pravidly veřejné podpory.',
				check_2_6_e: 'Žadatel si uplatňuje paušální částku na náklady na zaměstnance v případě návrhu rozpočtu.',
				check_2_6_f: 'Žadatel prohlašuje, že po dobu realizace projektu bude mít zaměstnanou alespoň 1 osobu.',
				check_3_7_experience_crossborder: 'Partner má zkušenosti s realizací přeshraničních projektů.',
				check_3_7_own_application: 'Partner bude podávat vlastní projektovou žádost (malý projekt typu B).',
				project_duration: 'Doba realizace projektu',
				project_result: 'Ukazatele výstupu a výsledku',
				scheduledStart: 'Zahájení realizace projektu',
				scheduledEnd: 'Ukončení realizace projektu',
				numberOfCall: 'Číslo výzvy',
				ttl_1_3: '',
				ttl_1_3_1: '',
				deleteProject: 'Smazat projekt',
				finance_1: 'Celkové náklady na projekt',
				finance_2: 'Finanční příspěvek z EFRR max. 80 %',
				finance_3: 'Finanční příspěvek žadatele min. 20 %',
				finance_amount: 'částka v EUR',
				finance_percent: '% podíl',
				finance_resources: 'Finanční zdroje',
				finance_contribution: 'Finanční příspěvek žadatele se skládá z:',
				finance_contribution_2: 'Finanční příspěvek partnera při společném financování',
				finance_contribution_2_max_warning: 'Finanční příspěvek partnera činí min. 5 % celkových nákladů na projekt.',
				finance_contribution_3: 'Vlastní podíl žadatele',
				project_type_2: 'people-to-people / P-T-P (02)',
				project_type_1: 'Kultura a cestovní ruch / KCR (01)',
				export_visibility_0: 'Nepodané',
				export_visibility_1: 'Podané',
				unit_cost: 'Celkové jednotkové náklady',
				draft_budget: 'Návrh rozpočtu (součet přímých a paušálních nákladů)',
				total_budget: 'Celkové náklady na projekt (součet)'
			},
			results: {
				rco_77: 'Výstup / RCO77 / Počet podpořených lokalit v oblasti kultury a cestovního ruchu / cílová hodnota',
				rcr_77: 'Výsledek / RCR77 / Počet návštěvníků podpořených lokalit v oblasti kultury a cestovního ruchu  / cílová hodnota',
				rco_83: 'Výstup / RCO83 / Společně vypracované strategie a akční plány  / cílová hodnota',
				rcr_79: 'Výsledek / RCR79 / Společné strategie a akční plány přijaté organizacemi  / cílová hodnota',
				rco_87: 'Výstup / RCO87 / Organizace zapojené do přeshraniční spolupráce  /  cílová hodnota',
				rcr_84: 'Výsledek / RCR84 / Organizace zapojené do přeshraniční spolupráce po dokončení projektu  / cílová hodnota',
				rco_116: 'Výstup / RCO116 / Společně zpracovaná řešení  / cílová hodnota',
				rcr_104: 'Výsledek / RCR104 / Řešení přijatá nebo rozvíjená organizacemi  / cílová hodnota',
				rco_81: 'Výstup / RCO81 / Účast na společných přeshraničních akcích  / cílová hodnota',
				rcr_85: 'Výsledek / RCR85 / Účast na společných přeshraničních akcích po dokončení projektu  / cílová hodnota',
				rco_115: 'Výstup / RCO115 / Společně organizované přeshraniční veřejné akce  / cílová hodnota',
				description: 'Okomentování cílové hodnoty (max. 600 znaků)',
			},
			activities: {
				addBtn: 'Přidat',
				removeBtn: 'Odebrat'
			},
			attachment_items: {
				at_0a: 'B1a: Doklad o právní subjektivitě žadatele',
				at_0: 'B1b: Doklad o právní subjektivitě partnerů',
				at_1a: 'B2a: Doklad o jednatelském oprávnění žadatele, pokud není uvedeno v B1a',
				at_1: 'B2b: Doklad o jednatelském oprávnění partnerů, pokud není uvedeno v B1b',
				at_2: 'B3: Prohlášení o partnerství',
				at_3: 'B4: Příslib národního spolufinancování (platí u rakouských žadatelů)',
				at_4: 'B5: Rozpočet projektu – list 1, přehled cenových nabídek - list 2',
				at_5: 'B6a/b: Čestné prohlášení žadatele',
				at_6: 'B7: Cenové nabídky, průzkum trhu',
				at_7: 'B8: Projektová  dokumentace na stavební práce',
				at_8: 'B9: Smlouva o převodu vlastnického práva při nákupu nemovitosti',
				at_9: 'B10: Stavební povolení',
				at_10: 'B11: Propočet stavby s podrobným rozpočtem stavby',
				at_11: 'B12: Dokumenty prokazující, že projekt nemá negativní vliv na území soustavy Natura 2000',
				at_12: 'B13: Prokázání vlastnického práva k nemovistostem'
			},
			popovers: {
				legal_form: 'Český žadatel vyplní právní formu organizace dle přílohy A1, rakouský žadatel vyplní právní formu organizace.',
				legal_form_2: 'Český žadatel vyplní právní formu organizace dle přílohy A1, rakouský žadatel vyplní právní formu organizace.',
				other_data_4: 'viz Příručka pro FMP bod 1.11.',
				cross_border: 'Projekt musí splňovat alespoň tři ze čtyř kritérií spolupráce. Povinné je splnění dvou následujících kritérií.',
				results: 'Pokyny jsou uvedeny v Příručce pro FMP, bod 2.3',
				budget: 'Podrobný rozpočet projektu je povinnou přílohou B5, zde se uvádějí souhrnná čísla z přílohy B5',
				attachment: 'Názvy nahraných souborů budou začínat kódem podle seznamu příloh (např. B5-rozpočet projektu.pdf). viz Příručka pro FMP bod 4.3'
			}
		},
		preview: {
			stateLabel: 'Stav projektu',
			downloadBtn: 'Exportovat žádost do PDF',
			logHistory: 'Historie stavů',
			stateLabelAdmin: 'Admin',
			stateLabelUser: 'User',
			changeState: 'Opravdu změnit stav?',
		},
		statesBtn: {
			all_filter: 'Stav (vše)',
			registered: 'Žádost zaregistrována',
			correction_1: 'Žádost vrácená k přepracování',
			fix_1: 'Opravená žádost znovu zaregistrována',
			accepted: 'Formální náležitosti splněny',
			refused_formal: 'Formální náležitosti nesplněny',
			quality_complied: 'Kritéria kvality a přeshr. dopadu splněny',
			quality_refused: 'Kritéria kvality a přeshr. dopadu nesplněny',
			refused: 'RMV pozastaven, doporučen k přepracování/ zamítnut',
			running: 'RMV schválen',
			contract: 'Podepsána smlouva o financování',
			unrealized: 'Projekt nerealizován',
			certificate: 'Vydáno osvědčení Správcem/FLC',
			report: 'Projekt v MZ Správců',
			paid: 'EFRR proplaceny',
			sustainability: 'Správa udržitelnosti'
		},
		footer: {
			text: ''
		},
		settings: {
			title: 'Nastavení',
			number_1: 'Číslo výzvy (p-t-p)',
			number_2: 'Číslo výzvy (kultura a cestovní ruch)',
			tooltip: 'Slouží k nastavení aktuálního čísla výzvy.'
		},
		messages: {
			success: 'Změna proběhla úspěšně',
			error: 'Operace se nezdařila',
			file_uploaded: 'Soubor byl úspěšně nahrán.',
			deleted: 'Položka byla smazána',
			wrong_login: 'Účet neexistuje, nebo nebyl aktivován ve vaší e-mailové schránce.',
			pswd_not_same: 'Hesla se neshodují',
			pswd_empty: 'Heslo nesmí být prázdné',
			automatic_save: 'Formulář byl automaticky uložen',
		},
		navbar: {
			users: 'Uživatelé',
			projects: 'Projekty',
			meetings: 'Zasedání',
		},
		users: {
			title: 'Uživatelé',
			name: 'Jméno',
			email: 'E-mail',
			tel: 'Telefon',
			permissions: 'Oprávnění',
			changePswd: 'Změnit heslo?',
			newPswd: 'Nové heslo',
			newPswdControl: 'Potvrzení nového hesla',
			role: {
				admin : 'Admin',
				user : 'Žadatel',
				'read-only' : 'ŘO/NO/JTS',
				'read-only-2' : 'Kontrolor',
				'meetings-only' : 'Člen výboru',
			}
		},
		meetings: {
			title: 'Zasedání',
			tableDateCol: 'Datum zasedání',
			tableName: 'Název zasedání',
			addMeeting: 'Přidat zasedání',
		},
	},
	de: {
		title: 'Registrierung in den Kleinprojektefonds<br> im Rahmen des Programms INTERREG<br> Österreich–Tschechien 2021-2027',
		universalTxt: {
			closeBtn: 'Schliessen',
			saveBtn: 'Speichern',
			delete: 'Löschen',
			logout: 'Abmelden',
			edit: 'Bearbeiten',
			edit_final: '',
			exportDatabank: 'Datenbank Export',
			visible_on: 'Sichtbar machen',
			visible_off: 'Ausblenden',
		},
		homepage: {
			registration_q: 'Sind Sie nicht registriert?',
			register: 'Registrieren Sie sich',
			cz: 'Tschechisch',
			de: 'Deutsch'
		},
		passwordReset: {
			title: 'Passwort erneuern',
			message: 'Füllen Sie Ihre Registrierungs-E-Mail aus und wir senden Ihnen ein neues Passwort zu.',
			reset: 'Passwort erneuern',
			change: 'Passwort ändern',
			passwdTest: 'Passwortüberprüfung',
			passwd: 'Passwort',
			login: 'Anmeldung'
		},
		login: {
			title: 'Anmeldung',
			login: 'Melden Sie sich an',
			passwdForget: 'Haben Sie Passwort vergessen?'
		},
		registration: {
			reg: 'Registrieren Sie sich',
			email: 'E-mail',
			pswd: 'Passwort',
			pswdTest: 'Passwortüberprüfung',
			regBtn: 'Sich registrieren',
			login: 'Sie sind bereits registriert und möchten sich anmelden?',
			backToHp: 'Zurück zur Startseite',
		},
		registrationConfirm: {
			login: 'Melden Sie sich an'
		},
		projects: {
			title: 'Projekte',
			newProject: 'Neues Projekt anlegen',
			downloadBtn: 'In PDF exportieren',
			attachments: 'Anhänge',
			infoAboutUploadFile: 'Folgende Dateitypen dürfen hochgelden werden: image/*, .pdf, .xlsx, .docx, .xls, .ppt, .doc, .odt, .ods, .rar, .zip.',
			maxFileSize: 'Die maximale Größe der hochgeladenen Dateien beträgt <b>128 MB</b>.',
			fileCompress: 'Komprimieren Sie große Dateien ausreichend in eine .zip oder .rar Datei.',
			phase_1: '1.) Füllen Sie das Formular aus',
			phase_2: '2.) Das ausgefüllte Formular herunterladen',
			phase_3: '3.) Hochladen von Anhängen',
			phase_4: '4.) Einreichung der endgültigen Version des Projektantrags',
			checkProjectBtn: 'Projektantrag zur Registrierung senden',
			checkProjectBtn1: 'Bearbeiteten Projektantrag zur Neuregistrierung senden',
			messageSend: 'Das Formular wurde zur Registrierung gesendet',
			deleteItem: 'Wirklich löschen?',
			tableColVisibility: 'Sichtbarkeit für: ',
			tableColDate: 'Einreichung des Projektantrags',
			tableColCall: 'Einreichrunde Nr.',
			tableColRegN: 'Reg. Nr.',
			tableColName: 'Titel',
			tableColUserName: 'Antragsteller',
			tableColState: 'Status',
			documents: 'Projektdokumentation',
			confirmText: 'Der Projektantrag wird nun eingereicht und zur Registrierung weitergeleitet. Sobald der Projektantrag zur Registrierung gesendet wurde, kann er nicht mehr bearbeitet werden. Möchten Sie fortfahren?',
			type_1: 'KT',
			type: 'Art Projekte',
			form: {
				ttl_1: '1. ALLGEMEINE INFORMATIONEN ÜBER DAS PROJEKT',
				ttl_2: '2. ANGABEN ZUM ANTRAGSTELLER',
				ttl_3: 'ANGABEN ZUM PROJEKTPARTNER Nr.',
				ttl_4: 'ANGABEN ZUM PROJEKT',
				ttl_5: 'QUALITÄT DER GRENZÜBERSCHREITENDEN ZUSAMMENARBEIT',
				ttl_6: 'KOSTEN- UND FINANZIERUNGSPLAN',
				showPartner2: 'Projektpartner Nr. 2 hinzufügen',
				removePartner2: 'Projektpartner Nr. 2 entfernen' ,
				ph_2_4_number: 'Kontonummer',
				ph_2_4_code: 'Bankcode',
				ph_2_4_name: 'Name der Bank',
				ph_2_4_iban: 'IBAN',
				ph_2_4_bic_swift: 'BIC/SWIFT',
				ph_2_5_contact: 'Vorname, Nachname, Funktion',
				ph_2_8_contact: 'Vorname, Nachname',
				ph_2_5_tel: 'Telefon (+420 oder +43)',
				ph_2_5_email: 'E-mail',
				check_2_6_a: 'Der Antragsteller ist nach gültiger Rechtslage für die im Projekt durchzuführenden Aktivitäten zum Vorsteuerabzug berechtigt.',
				check_2_6_c: 'Der Antragsteller hat Erfahrungen mit der Umsetzung von grenzüberschreitenden Projekten.',
				check_2_6_d: 'Das Projekt steht im Einklang mit den Vorschriften für öffentliche Beihilfen.',
				check_2_6_e: 'Der Antragsteller beansprucht eine Personalkostenpauschale im Falle des Draftbudgets.',
				check_2_6_f: 'Der Antragsteller erklärt, dass er mindestens eine Person für die Dauer des Projekts beschäftigen wird.',
				check_3_7_experience_crossborder: 'Der Partner hat Erfahrungen mit der Umsetzung von grenzüberschreitenden Projekten.',
				check_3_7_own_application: 'Vom Projektpartner wird ein separater Projektantrag eingereicht (Kleinprojekt Typ B).',
				project_duration: 'Projektlaufzeit',
				project_result: 'Output- und Ergebnisindikatoren',
				scheduledStart: 'Beginn der Projektumsetzung',
				scheduledEnd: 'Ende der Projektumsetzung',
				numberOfCall: 'Einreichrunde Nr.',
				ttl_1_3: '1.3. ',
				ttl_1_3_1: '1.3.1 ',
				deleteProject: 'Projekt löschen',
				finance_1: 'Projektgesamtkosten',
				finance_2: 'Beantragte Kleinprojekt-Förderung max. 80 %',
				finance_3: 'Finanzieller Beitrag des Antragstellers min. 20 %',
				finance_amount: 'Betrag in EUR',
				finance_percent: '% Anteil',
				finance_resources: 'Finanzierungsquellen',
				finance_contribution: 'Finanzieller Beitrag des Antragstellers bestehend aus:',
				finance_contribution_2_max_warning: 'Der finanzielle Beitrag des Partners beträgt min. 5 % der Projektgesamtkosten.',
				finance_contribution_2: 'Finanzierung des Partners bei gemeinsamer Finanzierung ',
				finance_contribution_3: 'Eigenmittel des Antragstellers',
				project_type_2: 'People-to-people / P-T-P (02)',
				project_type_1: 'Kultur und Tourismus / KT (01)',
				export_visibility_0: 'Nicht eingereichte',
				export_visibility_1: 'Eingereichte',
				unit_cost: 'Gesamteinheitskosten - gilt nur für tschechische Antragsteller',
				draft_budget: 'Budgetentwurf (Draft Budget)',
				total_budget: 'Projektgesamtkosten (Summe)'
			},
			results: {
				rco_77: 'Output / RCO77 / Anzahl der unterstützten kulturellen und touristischen Stätten / Zielwert',
				rcr_77: 'Ergebnis / RCR77 / Besucher von unterstützten kulturellen und touristischen Stätten  / Zielwert',
				rco_83: 'Output / RCO83 / Gemeinsam entwickelte Strategien und Aktionspläne  / Zielwert',
				rcr_79: 'Ergebnis / RCR79 / Von Organisationen aufgegriffene gemeinsame Strategien und Aktionspläne  / Zielwert',
				rco_87: 'Output / RCO87 / Grenzüberschreitend kooperierende Organisationen  /  Zielwert',
				rcr_84: 'Ergebnis / RCR84 / Organisationen, die nach Projektabschluss grenzüberschreitend zusammenarbeiten  / Zielwert',
				rco_116: 'Output / RCO116 / Gemeinsam entwickelte Lösungen  / Zielwert',
				rcr_104: 'Ergebnis / RCR104 / Von Organisationen aufgegriffene bzw. ausgebaute Lösungen  / Zielwert',
				rco_81: 'Output / RCO81 / Teilnahmen an grenzüberschreitenden gemeinsamen Maßnahmen  / Zielwert',
				rcr_85: 'Ergebnis / RCR85 / Teilnahmen an grenzüberschreitenden gemeinsamen Maßnahmen nach Projektabschluss  / Zielwert',
				rco_115: 'Output / RCO115 / Gemeinsam veranstaltete grenzüberschreitende öffentliche Veranstaltungen  / Zielwert',
				description: 'Beschreibung des Zielwertes (max. 700 Zeichen)',
			},
			activities: {
				addBtn: 'Hinzufügen',
				removeBtn: 'Entfernen'
			},
			attachment_items: {
				at_0a: 'B1a: Nachweis der Rechtspersönlichkeit des Antragstellers',
				at_0: 'B1b: Nachweis der Rechtspersönlichkeit aller Partnerorganisationen',
				at_1a: 'B2a: Nachweis der Vertretungsberechtigung des Antragstellers, falls nicht aus B1a ersichtlich',
				at_1: 'B2b:  Nachweis der Vertretungsberechtigung aller Partnerorganisationen, falls nicht aus B1b ersichtlich',
				at_2: 'B3: Partnerschaftserklärung',
				at_3: 'B4: nationale Kofinanzierungserklärung – gilt für österreichische Antragsteller',
				at_4: 'B5: Projektkostenplan – Tabelle 1, Preisangebotsübersicht – Tabelle 2',
				at_5: 'B6a/b: Ehrenerklärung des Antragstellers',
				at_6: 'B7: Preisauskünfte, Marktrecherche',
				at_7: 'B8: Projektdokumentation für Baumaßnahmen, Kostenvoranschlag für Bauarbeiten'
			},
			popovers: {
				legal_form: 'Tschechischer Antragsteller füllt die Rechtsform der Organisation laut Anhang A1 aus, österreichischer Antragsteller füllt die Rechtsform der Organisation aus.',
				legal_form_2: 'Tschechischer Antragsteller füllt die Rechtsform der Organisation laut Anhang A1 aus, österreichischer Antragsteller füllt die Rechtsform der Organisation aus.',
				other_data_4: 'Siehe Handbuch für KPF Punkt 1.11',
				cross_border: 'Das Projekt muss mindestens drei von vier Kooperationskriterien erfüllen. Verpflichtend ist die Erfüllung folgender beiden Kriterien.',
				results: 'Hinweise sind im Handbuch für KPF, Punkt 2.3 angeführt',
				budget: 'Ein detaillierter Projektkostenplan ist ein verpflichtender Anhang B5, hier werden die zusammengefassten Zahlen aus dem Anhang B5 angegeben',
				attachment: 'Die Namen der hochgeladenen Dateien beginnen mit dem Code, der in der Liste der Anhänge angegeben ist (z.B. B5-Projektbudget.pdf) - siehe Handbuch für KPF Punkt 4.3'
			}
		},
		preview: {
			stateLabel: 'Projektstatus',
			downloadBtn: 'Den Projektantrag in PDF exportieren',
			logHistory: 'Historie stavů',
			stateLabelAdmin: 'Admin',
			stateLabelUser: 'User',
			changeState: 'Wirklich Projektstatus ändern?',
		},
		statesBtn: {
			all_filter: 'Projektstatus (alle Möglichkeiten)',
			registered: 'Projektantrag registriert',
			correction_1: 'Projektantrag zur Überarbeitung retourniert',
			fix_1: 'Korrigierter Projektantrag neu registriert',
			accepted: 'Formale Anforderungen erfüllt',
			refused_formal: 'Formale Anforderungen nicht erfüllt',
			quality_complied: 'Kriterien Qualität und gü. Wirkung erfüllt',
			quality_refused: 'Kriterien Qualität und gü. Wirkung nicht erfüllt',
			refused: 'vom RLA zurückgestellt, zur Überarbeitung empfohlen',
			running: 'vom RLA genehmigt',
			contract: 'Finanzierungsvertrag unterfertigt',
			unrealized: 'Projekt nicht umgesetzt',
			certificate: 'Bescheinigung durch Verwalter/FLC ausgestellt',
			report: 'Projekt im Bericht der Verwalter',
			paid: 'EFRE-Mittel ausbezahlt',
			sustainability: 'Dauerhaftigkeitsbericht'
		},
		footer: {
			text: ''
		},
		settings: {
			title: 'Einstellung',
			number_1: 'Einreichrunde Nr. (p-t-p)',
			number_2: 'Einreichrunde Nr. (Kultur und Touristik)',
			tooltip: 'Dient zur Einstellung der aktuellen Einreichrunde Nummer.'
		},
		messages: {
			success: 'Die Änderung war erfolgreich',
			error: 'Der Vorgang ist fehlgeschlagen',
			file_uploaded: 'Die Datei wurde erfolgreich hochgeladen.',
			deleted: 'Position wurde gelöscht.',
			wrong_login: 'Das Konto existiert nicht oder wurde nicht in Ihrem E-Mail-Posteingang aktiviert.',
			pswd_not_same: 'Die Passwörter stimmen nicht überein',
			pswd_empty: 'Passwort darf nicht leer sein',
			automatic_save: 'Das Formular wurde automatisch gespeichert',
		},
		navbar: {
			users: 'Benutzer',
			projects: 'Projekte',
			meetings: 'Sitzungen',
		},
		users: {
			title: 'Benutzer',
			name: 'Name',
			email: 'E-Mail',
			tel: 'Telefon',
			permissions: 'Berechtigung',
			changePswd: 'Passwort ändern?',
			newPswd: 'Neues Passwort',
			newPswdControl: 'Neues Passwort bestätigen',
			role: {
				admin : 'Admin',
				user : 'Antragsteller',
				'read-only' : 'ŘO/NO/ JTS',
				'read-only-2': 'Kontrolle',
				'meetings-only' : 'Mitglied des Ausschusses',
			}
		},
		meetings: {
			title: 'Sitzung',
			tableDateCol: 'Datum der Sitzung',
			tableName: 'Name der Sitzung',
			addMeeting: 'Sitzung hinzufügen',
		},
	},
}


const i18n = new VueI18n({
	locale: lang,
	messages
});

export default i18n;
