import { render, staticRenderFns } from "./local-file-manager.vue?vue&type=template&id=8efe3ab8&scoped=true&"
import script from "./local-file-manager.vue?vue&type=script&lang=js&"
export * from "./local-file-manager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8efe3ab8",
  null
  
)

export default component.exports