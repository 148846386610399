<template>

    <b-navbar toggleable="lg" type="light" class="c-navbar" sticky>
        <b-container>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    
            <b-collapse v-if="isLoggedIn" id="nav-collapse" class="" is-nav>
                <b-navbar-nav>
    
                    <b-nav-item v-if="role !== 'meetings-only'" to="/projects">{{this.$t('navbar.projects')}}
                    </b-nav-item>

                    <b-nav-item v-if="role == 'admin' || role == 'meetings-only'" to="/meetings">{{this.$t('navbar.meetings')}}
                    </b-nav-item>
    
                    <b-nav-item v-if="role == 'admin'" to="/users">{{this.$t('navbar.users')}}
                    </b-nav-item>


                </b-navbar-nav>
    
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="changeLocalization(newLang)" >
                        {{ newLang }}
                    </b-nav-item>
                    <b-nav-item v-if="role == 'admin'" to="/settings" title="">
                        <b-icon class="clickable ml-1"
                                icon="gear-fill" variant=""
                        ></b-icon>
                    </b-nav-item>
                    <div v-if="role == 'admin'" class="nav-item" >
                        <router-link class="nav-link" :to="{name: 'userEdit', params: {id: userData.id}}">
                            <span>
                                <b-icon class="clickable ml-1"
                                        icon="person-fill" variant=""
                                ></b-icon>
                                {{userData.name}}
                            </span>
                        </router-link>
                    </div>
                    <b-nav-item v-if="isLoggedIn" @click="logout()">
                        <b-icon :title="$t('universalTxt.logout')"
                                class="clickable ml-1"
                                icon="box-arrow-right" variant=""
                        ></b-icon>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            
            <b-collapse v-else id="nav-collapse" class="ml-3" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="changeLocalization(newLang)" >
                        {{ newLang }}
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>

</template>

<script>
    export default {
        name: 'the-navbar',
        data() {
            return {
                path: process.env.VUE_APP_SERVER,
                newLang: (JSON.parse(localStorage.getItem('local')) == 'cs') ? 'de' : 'cs'
            }
        },
        computed : {
            isLoggedIn: function () {
                return this.$store.getters.isLogged
            },
            userData: function () {
                try {
                    return this.$store.getters.user
                } catch (err) {
                    return false
                }
                
            },
            role () {
                try {
                    return this.$store.getters.user.permission;
                } catch (err) {
                    return false
                }
            },
            
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/')
                    })
            },
            changeLocalization: function (newLang) {
                this.$i18n.locale = newLang;
                localStorage.setItem('local', JSON.stringify(newLang));
                this.newLang = ((newLang) == 'cs') ? 'de' : 'cs';
                this.$store.dispatch('setLocalizationInServer', newLang);
            }
        },
    }
</script>

<style  lang="scss">

</style>
